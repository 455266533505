import React from 'react'

function cover() {
  return (
    <div className='wrapper'>
       <div className='coverContainer'>
        <a className='clickableText' href="/"><h1>Make your own luck</h1></a>
        <p>Created by Alex Parsons</p>
        </div>
    </div>
  )
}

export default cover