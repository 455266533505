import pb from './images/powerball.jpg'
import mm from './images/megamillions.jpg'

export const power = {
    image: pb,
    classCalled: 'powerballImage'
}

export const mega = {
    image: mm,
    classCalled: 'megaImage'
}